@import './../../../styles/variables';
@import './../../../styles/variables';

.table__container{
    display: flex;
    flex-direction: column;
    gap: $base-box-spacig;
    background-color: white;
    padding: $large-box-spacig;
    margin: $small-box-spacig;
    border-radius: $standard-border-raius;
    text-align: start;
}
.table__title{
    font-size: $title-font-size;
}