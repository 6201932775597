@import './../../../styles/variables';
.fak__container{
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    background-color: $secondary-background-color;
    padding: $large-spacing;
    gap: $large-spacing;;
}
.fak__answer{
    text-align: start;
}
@media (width < 810px) {
    .fak__container{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
    }
}