@import './../../../styles/variables';

.block__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    margin-top: 100px;
    height: 80vh;
    background-color: $secondary-background-color;
    text-align: start;
}
.block__image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.block__info{
    padding: $large-box-spacig;
    align-self: center;
}

.block__info-subtitle{
    text-transform: uppercase;
    letter-spacing: 5px;
    color: $primary-color;
    font-weight: 600;
}

.block__info-title{
    color: $primary-text-color;
    font-size: $heading-font-size;
}
.block__info-description{
    color: $secondary-text-color;
}
@media (width < 900px) {
    .block__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 160vh;
    }
}