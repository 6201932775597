@import './../../../styles/variables';

.p-menubar {
    border: none;
    border-radius: 0px;
    position: fixed;
    z-index: 10;
    width: 100%;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid $light-border-color;
}

.menu__icon{
    margin-left: $large-spacing;
    margin-right: $large-spacing;
    transition: opacity 0.3s ease;
}
.menu__button{
    margin-right: $large-spacing;
}

.menu__standard-color{
    background-color: black;
}

.menu__light-color{
    background-color: white;
}

@media (width <= 960px) {
    .menu__icon{
        display: none;
    }   
}