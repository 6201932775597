@import './../../../styles/variables';
.review__container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    text-align: start;
    margin-top: 100px;
}
.review__info{
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    gap: $base-spacing;
    padding: $large-spacing;
}
.review__image{
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    justify-content: center;
    align-self: center;
}
.review__image img{ 
    max-height: 400px;
}
.review__title{
    font-size: $heading-font-size;
}
@media (width < 1080px) {
    .review__container{
        grid-template-columns: repeat(1, 1fr);
    }
    .review__image{
        display: none;
    }
}