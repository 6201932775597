@import './../../../styles/variables';

.gallery__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}
.gallery__subtitle{
   text-transform: uppercase;
   letter-spacing: 5px;
   font-weight: 600; 
   color: $primary-text-color;
}
.gallery__title{
    font-size: $heading-font-size;
}
.gellery__description{
    color: $secondary-text-color;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 700px;
}
.gallery__images-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $large-box-spacig;
    justify-content: center;
    width: 100%;
}
.gallery__image-item{
    width: 300px;
    height: 300px;
    border: 1px solid $light-border-color;
    background-color: black;
}
.gallery__image-item:hover{
    cursor: pointer;
}
.gallery__image-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gallery__image-item p{
    display: none;
}
.gallery__image-item:hover img {
    opacity: 0.5;
}
.gallery__image-item:hover p{
    display: block;
    position: relative;
    color: $light-color;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    bottom: 150px;
    padding: 20px;
    z-index: 10;
}
