@import './../../../styles/variables';
.info-card__container {
    display: grid;
    gap: $large-box-spacig;
    margin-left: 5%;
    margin-right: 5%;
    padding: $large-box-spacig;
    grid-template-columns: repeat(3, 1fr);
    background-color: $primary-background-color;
    color: $light-color;
    text-align: start;
    border-radius: 12px;
}
.info-card__info{
    text-align: start;
    grid-column: 1 / 3;
    place-self: center;
}
.info-card__info h2 {
    font-size: $title-font-size;
    font-weight: 600;
}
.info-card__button{
    grid-column: 3 / 4;
    justify-self: center;
    align-self: center;
}
.info-card__button button {
    max-width: 320px;
}
@media (width <= 1115px) {
    .info-card__container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }
    .info-card__info{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
    .info-card__button{
        grid-column: 2 / 3;
        grid-row: 2 /3;
    }
}